import React from "react";
import LawListHeader from "./LawListHeader";
import ListContent from "./ListContent";

const LawListContent = ({lawCategory, country}:any) => {

    return (
        <>
            {/*<CaseLawsHeader />*/}

            <div className="container">
                <div className="row">
                    <ListContent lawCategory={lawCategory} country={country} />
                </div>
            </div>
        </>
    )
}

export default LawListContent
