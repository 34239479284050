import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";

const CardType2 = ({
                       title,
                       date,
                       room,
                       number,
                       treatWith,
                       place,
                       getSelected,
                       link,
                       lawKey
                   }: { title: string, date: string, room: string, number: string, treatWith: string, place: string, link: string, lawKey: number, getSelected: (id: number) => void }) => {
    const {t} = useTranslation()

    return (
        <div className="card card-border-start border-primary m-2 w-100 m-v-10 shadow-lg">
            <div className="card-body d-flex flex-row flex-nowrap p-3 justify-content-between align-items-start">
                <a className="text-decoration-none" href={link}>
                    <div>
                        <h5>{title}</h5>
                        <p className="px-2"> {number + ' ' + room} </p>
                        <i className="font-weight-bold fs-15">{lawKey}</i>
                    </div>
                </a>
                <div className="d-flex flex-column justify-content-end nav-item dropdown" style={{cursor: "pointer"}}>
                    <i className="uil uil-ellipsis-h text-primary p-2 fs-25 text-purple"
                       data-bs-toggle="dropdown" data-toggle="tooltip" data-placement="bottom" title="Action"></i>
                    <div className="dropdown-menu dropdown-menu-start" style={{right: "0", top: "3em", left: 'auto'}}>
                        <div className="dropdown-item"
                             style={{cursor: 'pointer'}}
                             data-bs-target="#modal-addCaseLaw0"
                             data-bs-toggle="modal"
                             onClick={() => {
                                 getSelected(lawKey)
                             }}>
                            <p className="dropdown-item-text"> {t('Edit')} </p>
                        </div>
                        <div className="dropdown-item"
                             style={{cursor: 'pointer'}}
                             onClick={() => {
                                 getSelected(lawKey)
                             }}
                             data-bs-target="#modal-delete"
                             data-bs-toggle="modal"
                        >
                            <p className="dropdown-item-text text-red"> {t('Delete')} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CardType2;
