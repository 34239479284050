import React, {useEffect, useState} from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import CardType2 from "../card/CardType2";
import {initialCaseLaw, initialCCJA} from "../../util/type/types";
import {AddCcjaLaw} from "../modal/addCcjaLaw";
import {
    deleteCaseLaw, deleteCcjaCaseLaw,
    getCaseLawsCategories,
    getCaseLawSummaryCategories, getNationalCourtCaseLawCategories,
} from "../../queries";
import {DeleteModal} from "../modal/deleteModal";

const ListContent = ({lawCategory, country}: any) => {

    const [selectedLaw, setSelectedLaw] = useState<any>(null)
    const [caseLawCategories, setCaseLawCategories] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const {t, i18n} = useTranslation()
    let backLink = typeof window === 'object' && window?.location.pathname.split('/').slice(0, -1).join("/")
    const isInCcjaCaseLaw = typeof window === 'object' && window?.location.pathname.includes('ccjaCaseLaw')
    const isInCaseLawSummaries = typeof window === 'object' && window?.location.pathname.includes('caseLawSummaries')
    const isInNationalCourtsCaseLaw = typeof window === 'object' && window?.location.pathname.includes('nationalCourtsCaseLaw')
    useEffect(() => {
        setLoading(true)
        isInCcjaCaseLaw && getCaseLawsCategories(setCaseLawCategories, i18n.language)
        isInNationalCourtsCaseLaw && getNationalCourtCaseLawCategories(country, setCaseLawCategories, i18n.language)
        isInCaseLawSummaries && getCaseLawSummaryCategories(setCaseLawCategories, i18n.language)
        setLoading(false)
        return () => {
            setCaseLawCategories(null)
            setSelectedLaw(null)
        }
    }, [lawCategory, isInCcjaCaseLaw, isInCaseLawSummaries])

    return (
        <>
            <aside className="col-xl-2 order-xl-1 sidebar sticky-sidebar mt-md-0 py-16 d-none d-xl-block">
                <h6 className="widget-title fs-17 mb-2 ps-xl-5">{t('Relative Category')}</h6>
                <div className="widget">
                    <form className="search-form">
                        <div className="form-floating mb-0">
                            <input id="search-form" type="text" className="form-control"
                                   placeholder="Search"/>
                            <label htmlFor="search-form">{t('Search')}</label>
                        </div>
                    </form>
                </div>
                <div className="widget shadow-lg p-2">
                    <nav className="ps-xl-5" id="sidebar-nav">
                        <ul className="list-unstyled fs-sm lh-sm text-reset overflow-auto"
                            style={{
                                // maxWidth: '60px',
                                maxHeight: '300px',
                                scrollbarWidth: 'thin',

                            }}>
                            {caseLawCategories ? caseLawCategories.map((category: any, id: number) => {
                                    return (<li key={id} className="pe-auto shadow-lg py-1">
                                        <a
                                            style={{cursor: 'pointer'}}
                                            className={`nav-link ${lawCategory.name === category.name && 'active'}`}
                                            href={`${backLink}/${category.id}`}
                                        >
                                            {category.name}
                                        </a>
                                    </li>)
                                }) : loading ?
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only"></span>
                                </div> : ""
                            }
                        </ul>
                    </nav>
                </div>
            </aside>
            <div className="col-xl-10 order-xl-2">
                <section id="ste" className="wrapper py-10">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <h2 className="mb-5">{lawCategory.name}</h2>
                        <div className=""
                             data-bs-target="#modal-addCaseLaw0"
                             data-bs-toggle="modal">
                            <i className="uil uil-plus-circle text-primary p-2 fs-30 text-purple"
                               style={{cursor: "pointer"}}></i>
                        </div>
                    </div>
                    <h6 className="widget-title fs-17 mb-2 ps-xl-5">{t('Choose an element')}</h6>
                    <div className="widget">
                        <form className="search-form">
                            <div className="form-floating mb-0">
                                <input id="search-form" type="text" className="form-control"
                                       placeholder="Search"/>
                                <label htmlFor="search-form">{t('Search')}</label>
                            </div>
                        </form>
                    </div>
                    <div className="widget shadow-lg p-2">
                        <nav className="ps-xl-5" id="sidebar-nav">
                            <ul className="list-unstyled fs-sm lh-sm text-reset overflow-auto d-flex flex-row flex-wrap p-3 justify-content-start align-items-start"
                                style={{
                                    // maxWidth: '60px',
                                    maxHeight: '600px',
                                    scrollbarWidth: 'thin',

                                }}>
                                {lawCategory ? lawCategory.lawList?.map((law: any, idx: number) => {
                                        return (
                                            <CardType2
                                                title={law.title}
                                                key={idx}
                                                date={law.date}
                                                room={law.room}
                                                number={law.number}
                                                treatWith={law.treatWith}
                                                place={law.place}
                                                getSelected={(id: number) => {
                                                    setSelectedLaw(lawCategory?.lawList?.filter((item: { id: any; }) => parseInt(item.id) === id)[0])
                                                }}
                                                link={`${window?.location.pathname}/${law.id}`}
                                                lawKey={parseInt(law.id)}
                                            />
                                        )
                                    }) : loading ?
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only"></span>
                                    </div>: ""
                                }
                            </ul>
                        </nav>
                    </div>
                </section>
            </div>
            <DeleteModal name="CCJA Case Law" id={selectedLaw?.id} deleteFunction={(id: number) => {
                (isInCcjaCaseLaw || isInNationalCourtsCaseLaw) && deleteCcjaCaseLaw(id)
                isInCaseLawSummaries && deleteCaseLaw(id)
            }}/>
            {typeof window === "object" &&
                <AddCcjaLaw name={isInCcjaCaseLaw ? t('CCJA Case Law') :
                    isInCaseLawSummaries ? t('Case Law Summary') :
                        isInNationalCourtsCaseLaw ? t('National Court Case Law') : t('Case Law')}
                            selectId={0}
                            isInCcjaCaseLaw={isInCcjaCaseLaw}
                            isInCaseLawSummaries={isInCaseLawSummaries}
                            isInNationalCourtsCaseLaw={isInNationalCourtsCaseLaw}
                            initData={selectedLaw ? {
                                    id: selectedLaw?.id,
                                    title: selectedLaw?.title,
                                    date: selectedLaw?.date,
                                    room: selectedLaw?.room,
                                    number: selectedLaw?.number,
                                    treatWith: selectedLaw?.treatWith,
                                    place: selectedLaw?.place,
                                    categoryId: lawCategory?.id,
                                    type: selectedLaw?.type,
                                    docFileName: selectedLaw?.docFileName,
                                    summary: selectedLaw?.summary,
                                    body: selectedLaw?.body,
                                } :
                                (isInCcjaCaseLaw || isInNationalCourtsCaseLaw) ? {
                                    ...initialCCJA, categoryId: lawCategory?.id,
                                    type: isInCcjaCaseLaw ? 'CCJA-Case-Laws' :
                                        isInCaseLawSummaries ? 'Case-Law-Summaries' :
                                            isInNationalCourtsCaseLaw && 'National-Court-Case-Laws'
                                } : isInCaseLawSummaries ?
                                    {...initialCaseLaw, categoryId: lawCategory?.id} :
                                    { ...initialCCJA }
                            }


                />
            }
        </>
    )
}

export default ListContent


