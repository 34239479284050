import {loadScript} from "../../util/helper";
import Layout from "../../components/layout/Layout";
import {Helmet} from "react-helmet";
import {useEffect} from "react";
import React from "react";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";
import LawListContent from "../../components/lawList/LawListContent";
import {getCaseLawSummaryCategory, getLawCategory, getNationalCourtCaseLawCategory} from "../../queries";

const LawList = ({categoryId, country}: any) => {

    const [lawCategory, setLawCategory] = React.useState<any>({})
    useEffect(() => {
        window?.location.pathname.includes('ccjaCaseLaw') && getLawCategory(parseInt(categoryId), setLawCategory)
        window?.location.pathname.includes('nationalCourtsCaseLaw') && getNationalCourtCaseLawCategory(parseInt(categoryId), setLawCategory)
        window?.location.pathname.includes('caseLawSummaries') && getCaseLawSummaryCategory(parseInt(categoryId), setLawCategory)
        loadScript("/assets/js/plugins.js");
        setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 500)

    }, [categoryId, country])

    return (
        <>
            {lawCategory ?
                <>
                    <Helmet>
                        <title>
                            OHADA | {lawCategory?.id ? lawCategory.name: '' }
                        </title>
                    </Helmet>
                    <Layout title={`OHADARB | ${lawCategory.name}`}>
                        <LawListContent lawCategory={lawCategory} country={country}  />
                    </Layout>
                </>
                :
                <div className="page-loader"></div>
            }
        </>
    )
}

export default LawList;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
